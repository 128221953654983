import { Box, Center, Flex, Image, Text } from "@chakra-ui/react";
import { SwiperSlide } from "swiper/react";
import { NewsletterButton, useGlobalContext } from "~/providers";
import { NavLink } from "../Buttons";
import { FooterLinks, Partners } from "../Layout";
import Link from "next/link";
import { Subheading } from "../Text";

export const FooterPanel = () => {
  return (
    <Box
      as={SwiperSlide}
      w={{ base: "1400px", md: "1780px", lg: "2000px" }}
      h="100vh"
      display="flex"
      justifyContent="flex-end"
      alignItems="flex-end"
      backgroundImage="/FinalSection.png"
      backgroundSize={"cover"}
      flexDirection={"column"}
    >
      <Flex direction={{ base: "row" }} align={{ base: "flex-end" }}>
        <Box style={{ paddingTop: 64 }} maxW={{ base: 300, md: 600, lg: 750 }}>
          <Subheading
            color="black"
            fontSize={{ base: "2xl", md: "4xl" }}
            textAlign={"center"}
            mb={12}
          >
            About the Highway 3 Consortium 
          </Subheading>
          <Text
            color="black"
            fontSize={{ base: "md", md: "lg" }}
            w="100%"
            textAlign={"center"}
            fontFamily={"heading"}
          >
            The Highway 3 consortium is a collaboration of 13 community tourism
            areas from Hope to Elkford, BC which is represented by the following
            organizations: Boundary Country Tourism, Cranbrook Tourism, Creston
            Valley Tourism, Destination Castlegar, Destination Osoyoos, District
            of Elkford, Hope, Cascades and Canyons, Manning Park Resort, Nelson
            Kootenay Lake Tourism, Similkameen Valley Planning Society, Tourism
            Fernie, Tourism Kimberley and Tourism Rossland.
          </Text>
        </Box>
        <Center flexDirection="column" w="390px">
          <Image src="ShieldLogo.png" alt="" mb="32px" w="179px" />
          <Link target="_blank" href={"https://www.hellobc.com/ "}>
            <Image src="SNBCLogo.png" alt="" w="217px" />
          </Link>
          <Box padding={16} display={{ base: "flex", lg: "none" }}>
            <NewsletterButton />
          </Box>
        </Center>

        {/* <Center flexDirection="column" w="390px">
          <Flex
            direction={{ base: "column" }}
            justifyContent={{
              base: "space-evenly",
              md: "center",
              lg: "space-evenly",
            }}
            width={{ base: "100%", lg: "30%" }}
            style={{
              alignItems: "center",
            }}
          >
            <NavLink
              fontSize="md"
              target="_blank"
              noOfLines={1}
              href={"/about-us"}
            >
              About Us
            </NavLink>
            <NavLink
              fontSize="md"
              target="_blank"
              noOfLines={1}
              href={"/contact-us"}
            >
              Contact Us
            </NavLink>
          </Flex>
          <NavLink fontSize="md" noOfLines={1} href={"/privacy-policy"}>
            Privacy Policy
          </NavLink>
          <NavLink
            fontSize="md"
            target="_blank"
            noOfLines={1}
            href={"https://thenumber.ca/"}
          >
            Made by The Number
          </NavLink>
        </Center> */}
      </Flex>

      <FooterLinksHome />
    </Box>
  );
};

FooterPanel.displayName = "SwiperSlideFooterPanel";
const FooterLinksHome = () => {
  return (
    <Flex
      p={{ base: "64px 32px 32px 32px" }}
      w={{ base: "45%", md: "60%", lg: "60%" }}
      gap={{ base: 8, md: 10 }}
      wrap={{ base: "wrap" }}
      justify={{ base: "space-around" }}
      alignItems={"center"}
    >
      <NavLink fontSize="md" href={"/about-us"}>
        About Us
      </NavLink>
      <NavLink fontSize="md" href={"/contact-us"}>
        Contact Us
      </NavLink>
      <Box display={{ base: "none", lg: "flex" }}>
        <NewsletterButton />
      </Box>
      <NavLink fontSize="md" noOfLines={1} href={"/privacy-policy"}>
        Privacy Policy
      </NavLink>
      <NavLink fontSize="md" noOfLines={1} href={"https://thenumber.ca/"}>
        Made by The Number
      </NavLink>
    </Flex>
  );
};
